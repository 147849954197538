'use strict';

var formPrepare = require('../../components/formPrepare');
var util = require('../../components/util');
var progress = require('../../components/progress');
var accordion = require('../../components/accordion');
var address = require('../../components/address');
var clickandcollect = require('../../components/clickandcollect');
var checkout = require('./index');
var gtmTracking = require('../../components/gtmTracking');
var _throttle = require('lodash/throttle');
var addressSuggest = require('../../components/address-suggest');
var AccessibilityHelper = require('../../helper/accessibility-helper');

var $shippingForm = $("[id$='_singleshipping']");
var $checkoutShipping = $(".checkout-shipping");
var $firstName = $("input[name$='_firstName']");
var $lastName = $("input[name$='_lastName']");
var $companyName = $("input[name$='_companyName']");
var $address1 = $("input[name$='_address1']");
var $address2 = $("input[name$='_address2']");
var $zip = $("input[name$='_zip']");
var $city = $("input[name$='_city']");
var $country = $("select[name$='_country']");
var $state = $("select[name$='_states_state']");
var $email = $("input[name$='_emailAddress']");
var $phone = $("input[name$='_phone']");
var $optChk = $("input[name$='_addToEmailList']");
var $smsOptChk = $("input[name$='_addToSMSList']");
var $addressId = null;

/**
 * @function initGiftMessageBox()
 * @description Initializes gift message box functionality.
 */
var initGiftMessageBox = function () {
	// init left character count and max characters
	var max = $(".gift-message").data("giftmessagemax");
	var text = $(".gift-message span.max-char-limit").html();
	$(".gift-message span.max-char-limit").html(text.replace("XXX", "<span class=\"count\"></span>").replace("YYY", max));
	var count = $(".gift-message textarea").val().length;
	var left = max - count;
	$(".gift-message span.count").text(left);
};

/**
 * @function getShippingMethodURL()
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 */
var getShippingMethodURL = function (url, extraParams) {
	var params = {
		firstname: $firstName.val(),
		lastname: $lastName.val(),
		address1: $address1.val(),
		address2: $address2.val(),
		countryCode: $country.val(),
		stateCode: $state.val(),
		postalCode: $zip.val(),
		city: $city.val(),
		addressId: $addressId
	};
	// Add CSRF token
	var $shippingTokenInput = $('.shipping-token');
	params[$shippingTokenInput.attr('name')] = $shippingTokenInput.val();
	return util.appendParamsToUrl(url, $.extend(params, extraParams));
};

/**
 * @function selectShippingMethod()
 * @description selects a shipping method for the default shipment and updates the summary section on the right hand side
 * @param
 */
var selectShippingMethod = function (shippingMethodID) {
	// nothing entered
	if (!shippingMethodID) {
		return;
	}
	// attempt to set shipping method
	var url = getShippingMethodURL(Urls.selectShippingMethodsList, {shippingMethodID: shippingMethodID});

	$.getJSON(url, function (data) {

		checkout.updateSummary('shipping');

		if (!data || !data.shippingMethodID) {
			// console.error('Couldn\'t select shipping method.');
			return false;
		}
		// display promotion in UI and update the summary section,
		// if some promotions were applied
		$('.shippingpromotions').empty();

	});
};

/**
 * @function updateShippingMethodList()
 * @description Make an AJAX request to the server to retrieve the list of applicable shipping methods
 * based on the merchandise in the cart and the currently entered shipping address
 * (the address may be only partially entered).  If the list of applicable shipping methods
 * has changed because new address information has been entered, then issue another AJAX
 * request which updates the currently selected shipping method (if needed) and also updates
 * the UI.
 */
var updateShippingMethodList = function () {
	var $shippingMethodList = $('#shipping-method-list');
	if (!$shippingMethodList || $shippingMethodList.length === 0) {
		return;
	}
	var url = getShippingMethodURL(Urls.shippingMethodsJSON);

	$.getJSON(url, function (data) {
		if (!data) {
			// eslint-disable-next-line no-console
			console.error('Couldn\'t get list of applicable shipping methods.');
			return false;
		}

		progress.show($shippingMethodList);

		// load the shipping method form
		var smlUrl = getShippingMethodURL(Urls.shippingMethodsList);
		$shippingMethodList.load(smlUrl, function () {
			$shippingMethodList.fadeIn('fast');

			checkout.updateSummary('shipping');

			progress.hide();

			checkout.refreshTooltips();

			// if nothing is selected in the shipping methods select the first one
			if ($shippingMethodList.find("input[name$='_shippingMethodID']:checked").length === 0) {
				$shippingMethodList.find("input[name$='_shippingMethodID']").first().prop('checked', 'checked');
				checkout.updateSummary('shipping');
			}
			const clickAndCollectCheckbox = document.querySelector('#clickAndCollectToggle');

			if (clickAndCollectCheckbox && clickAndCollectCheckbox.checked) {
				selectShippingMethod(getClickAndCollectShippingMethodId());
			}

			toggleShippingMethodsDisplay(clickAndCollectCheckbox);
		});
	});
};

/**
 * @function clearAddressForm()
 * @description Selectively clears address form inputs
 */
var clearAddressForm = function () {
	var inputsToClear = [$firstName, $lastName, $companyName, $address1, $address2, $city, $zip];
	var selectsToClear = [$state];

	inputsToClear.forEach(function (element) {
		clearField(element);
	});

	util.checkInputVal(inputsToClear);
	hideStoredAddressError();

	selectsToClear.forEach(function (element) {
		address.changeFormSelection(element[0], '');
	});
};

/**
 * @function clearField()
 * @description clears an input field with validating messages
 */
function clearField(elm) {
	elm.val('');
	// remove error/success messaging
	elm.parent(".field-error").removeClass("field-error").find("span.field-error").remove();
	elm.parent(".field-success").removeClass("field-success");
}

// updates the address form with the attributes of a given address
var updateAddressForm = function (addressID, _address) {
	var _addressID = addressID;

	var updateAddressFormFields = function (data) {

		if (!address.changeFormSelection($country[0], data.address.countryCode)) {
			return;
		}

		// fill the form
		$firstName.val(data.address.firstName);
		$lastName.val(data.address.lastName);
		$companyName.val(data.address.companyName);
		$address1.val(data.address.address1);
		$address2.val(data.address.address2);
		$city.val(data.address.city);
		$zip.val(data.address.postalCode);

		address.updateState(data.address.countryCode, data.address.stateCode);

		$phone.val(data.address.phone);

		// Run validation on all shipping address fields.
		formPrepare.validateShippingForm();

		var inputsToCheck = [$firstName, $lastName, $companyName, $address1, $address2, $city, $zip, $phone];
		util.checkInputVal(inputsToCheck);

		// Address field populated based on the preferred address. Re-initialize marketing options.
		validateAndInitialiseMarketingOptin();
	};

	if (_address && typeof _address == 'object') {
		updateAddressFormFields(_address, addressID);
	} else {

		$.ajax({
			type: "GET",
			dataType: "json",
			cache: false,
			contentType: "application/json",
			url: Urls.getAddressDetails,
			data: {addressID: _addressID}

		}).done(function (data) {

			if (!data || !data.address) {
				// eslint-disable-next-line no-console
				console.error(Resources.ADDRESS_LOAD_ERROR + " " + Resources.PLEASE_SIGNIN);
				return;
			}

			// fill the form
			if (!address.containsSelectionElement($country[0], data.address.countryCode)) {
				showStoredAddressError(data.address.error);
			} else {

				updateAddressFormFields(data, addressID);

				// remove error messaging
				$("div.shipping-form span.field-error").remove();
				$("div.shipping-form input.field-error").removeClass("field-error");

				hideStoredAddressError();

				// Check if the list of shipping methods needs to change
				updateShippingMethodListWrapper();
			}

		}).fail(function (xhr, textStatus) {
			// failed
			if (textStatus === "parsererror") {
				// eslint-disable-next-line no-console
				console.error(Resources.BAD_RESPONSE);
			} else {
				// eslint-disable-next-line no-console
				console.error(Resources.SERVER_CONNECTION_ERROR);
			}
		});
	}
};

/**
 * @function showStoredAddressError()
 * @description
 */
var showStoredAddressError = function (error) {
	if (typeof (error) == "undefined") {
		error = "";
	}
	var message = error.length == 0 ? (Resources.VALIDATE_ERRORPREFIX + " " + Resources.NO_SHIP_TO_COUNTRY) : error;
	$(".addresscountryincorrect").html(message);
	$(".addresscountryincorrect").show();
};

/**
 * @function hideStoredAddressError()
 * @description
 */
var hideStoredAddressError = function () {
	$(".addresscountryincorrect").hide();
};

/**
 * @function initGoogleAutocomplete()
 * @description
 */
var initGoogleAutocomplete = function () {
	addressSuggest.init({
		shipToCountry: $checkoutShipping.data("shiptocountry"),
		address1: $address1,
		address2: $address2,
		city: $city,
		postalCode: $zip,
		state: $state,
		country: $country,
		onSelection: () => {
			// Run validation on all shipping address fields, except [phone]
			[$state, $zip, $city, $address2, $address1].forEach(function ($this) {
				$this.focus().blur();
				util.checkInputVal($this);
			});
		}
	});
};

/**
 * if click and collect shipping method exists in the DOM, hide it visually.
 * if click and collect is selected, hide the entire shipping method list section
 */
function toggleShippingMethodsDisplay(clickAndCollectCheckbox) {
	if (clickAndCollectCheckbox && clickAndCollectCheckbox.checked) {
		$('#shipping-method-list').hide();
	} else {
		$('#shipping-method-list').show();
	}
}

/**
 * function selects preferred address from the saved addresses
 */
function selectPreferredAddress() {
	$(".checkout-shipping").find(".addresslist input").each(function () {
		if ($(this).is(".preferred-address")) {
			$(this).prop('checked', true).trigger('change');
		}
	});
}

/**
 * @function initializeDOM()
 * @description
 */
var initializeDOM = function () {

	util.updateInputTypes($email, 'email');
	util.updateInputTypes($phone, 'tel');
	updateShippingMethodListWrapper();
	checkout.refreshTooltips();

	// Puts the address form in an accordion
	accordion.attach('#address-form', '.accordion-trigger', '.accordion-content');

	// show gift section if has gift wrap or gift message
	var accordionState = 'closed';
	if ($(".gift-message").length > 0) {
		if ($('textarea[name*="_giftMessage"]').val().length > 0) {
			accordionState = 'open';
		}

		initGiftMessageBox();
	}

	if ($(".gift-wrap").length > 0) {
		if ($('input[name$="_isGiftWrap"]').is(':checked')) {
			accordionState = 'open';
		}
	}

	accordion.attach('.pt_checkout .gift', 'h3', '.gift-box', accordionState);


	if ($address1.hasClass("googleautocomplete")) {
		initGoogleAutocomplete();
	}

	formPrepare.init({
		continueSelector: '[name$="shippingAddress_save"]',
		formSelector: '[id$="_singleshipping"]'
	});

	checkout.updateSummary('shipping');

	// click && collect
	const clickAndCollectCheckbox = document.querySelector('#clickAndCollectToggle');
	if (clickAndCollectCheckbox && clickAndCollectCheckbox.checked && clickAndCollectCheckbox.value) {
		executeClickAndCollectToggle(clickAndCollectCheckbox.value);
	}

	Tipped.create('.contact-info-tooltip', 'contact-info-tooltip-content', {
		inline: true,
		maxWidth: 200,
		skin: 'white',
		hook: 'topleft',
		hideOn: 'mouseleave'
	});

	const newsletterOptin = document.querySelector('#newsletterOptin');
	const smartOptinEnabled = !!(
		newsletterOptin &&
		newsletterOptin.dataset &&
		newsletterOptin.dataset.smartOptinEnabled == "true"
	);
	/**
	 * NOTE: There is only 1 T&Cs for marketing and rewards opt-in
	 * When smart optin is enabled at the site-level, show T&Cs only when rewards signup does not exist
	 */
	if (smartOptinEnabled) {
		const rewardsSignup = document.querySelector('#rewardsSignup');
		if (!rewardsSignup) {
			const termsAndConditions = document.querySelector('#termsAndConditions');
			termsAndConditions.classList.add("hidden");
		}
	}
};

var updateShippingTimeout;
var updateShippingMethodListWrapper = function () {
	if (updateShippingTimeout) {
		clearTimeout(updateShippingTimeout);
		const clickAndCollectCheckbox = document.querySelector('#clickAndCollectToggle');
		toggleShippingMethodsDisplay(clickAndCollectCheckbox);
	}
	updateShippingTimeout = setTimeout(updateShippingMethodList, 500);

};

/**
 * trigger events on click of the Click&Collect checkbox
 * @param storeIdFromSession - this value is null on a fresh page load. a valid value from backend session is passed on a re-visit to shipping page
 */
function executeClickAndCollectToggle(storeIdFromSession) {
	const clickAndCollectCheckbox = document.querySelector('#clickAndCollectToggle');
	const storeSelectionRadioGroup = document.querySelector('#storeSelectionRadioGroup');
	const eligibleStoresLoader = document.querySelector('#eligibleStoresLoader');
	if (clickAndCollectCheckbox) {
		if (clickAndCollectCheckbox.checked) {
			const actionUrl = clickAndCollectCheckbox.getAttribute('data-href');
			$.ajax({
				type: "GET",
				dataType: "json",
				cache: false,
				contentType: "application/json",
				url: actionUrl,
				beforeSend: function () {
					eligibleStoresLoader.style.display = "flex";
				},
				complete: function () {
					eligibleStoresLoader.style.display = "none";
				}
			}).done(function (data) {
				processToggleSelectionResponse(data, storeSelectionRadioGroup, storeIdFromSession);
				$(".checkout-shipping").addClass("click-and-collect-mode");
			}).fail(function (xhr, textStatus) {
				// failed
				if (textStatus === "parsererror") {
					// eslint-disable-next-line no-console
					console.error('parseError in executeClickAndCollectToggle. Bad response');
				} else {
					// eslint-disable-next-line no-console
					console.error('Server connection error in executeClickAndCollectToggle');
				}
			});
			$(".label-pickup").attr('aria-selected', 'true');
			$(".label-shipit").attr('aria-selected', 'false');
		} else {
			$(".checkout-shipping").removeClass("click-and-collect-mode");
			$(".click-and-collect-hint").hide();
			$('#storeDetailsSection').hide();
			$(storeSelectionRadioGroup).hide();
			postRequestToBackend(null, false);
			$(".label-shipit").attr('aria-selected', 'true');
			$(".label-pickup").attr('aria-selected', 'false');
		}
	}
}

/**
 * triggers events on selection of a store from dropdown
 */
function executeStoreSelectionAction() {
	const storeSelection = document.querySelector('input[name="store-selection"]:checked');
	if (storeSelection && storeSelection.value) {
		const storeInfo = JSON.parse(storeSelection.value);
		populateStoreDetailsAndDisplayBlock(storeInfo, false);
		postRequestToBackend(storeInfo, true);
	}
}

/**
 * Function updates basket's shipping address info with selected store's address
 * @param storeInfo
 */
function updateShippingFormField(storeInfo) {
	const storeName = storeInfo.name;
	const firstSpaceIndex = storeName.indexOf(' ');
	$firstName.val(storeName.substring(0, firstSpaceIndex));
	$lastName.val(storeName.substring(firstSpaceIndex + 1, firstSpaceIndex + 26));

	$address1.val(storeInfo.address1);
	$address2.val(storeInfo.address2);
	$city.val(storeInfo.city);
	$zip.val(storeInfo.zipCode);
	$state.val(storeInfo.stateCode);
	$country.val(storeInfo.countryCode);
}

/**
 * populates store info block and shipping form field
 * @param storeInfo
 * @param showSingleStoreMessage
 */
function populateStoreDetailsAndDisplayBlock(storeInfo, showSingleStoreMessage) {
	if (storeInfo) {
		const singleStoreMsgElement = document.querySelector('#singleStoreMsg');
		const storeNameElement = document.querySelector('#storeName');
		const storeAddressElement = document.querySelector('#storeAddress');
		const storeDirectionsElement = document.querySelector('#storeDirections');
		const orderLeadTimeElement = document.querySelector('#orderLeadTimeInfo');
		const storeLocation = storeInfo.city + (storeInfo.stateCode ? ", " + storeInfo.stateCode : "");
		const addressArray = [storeInfo.address1, storeInfo.address2, storeLocation, storeInfo.zipCode];

		storeNameElement.innerHTML = storeInfo.name;
		if (!showSingleStoreMessage) {
			storeNameElement.classList.add("focusable");
			storeNameElement.setAttribute("tabIndex", "0");
		}

		storeAddressElement.innerHTML = addressArray.filter((i) => i).join('<br />');
		storeDirectionsElement.href = storeInfo.directions;
		orderLeadTimeElement.innerHTML = storeInfo.orderLeadTimeMessage;
		// update shipping address form
		updateShippingFormField(storeInfo);

		$('#storeDetailsSection').show();
		singleStoreMsgElement.style.display = (showSingleStoreMessage) ? "block" : "none";
	}
}

/**
 * Function processes click and collect checkbox selection
 * @param data
 * @param storeSelectionRadioGroup
 */
function processToggleSelectionResponse(data, storeSelectionRadioGroup, storeIdFromSession) {
	let stores;
	let loadWithStore;
	let loadWithStoreIndex;
	let showSingleStoreMessage = false;
	const container = document.querySelector("#clickAndCollectEligibleStores");

	try {
		stores = JSON.parse(data);
	} catch (error) {
		// console.error(error);
	}

	if (stores && stores.length > 0) {
		loadWithStoreIndex = stores.findIndex((store) => storeIdFromSession && storeIdFromSession == store.ID);
		loadWithStore = loadWithStoreIndex >= 0 ? stores[loadWithStoreIndex] : null;

		clickandcollect.init(container, stores, stores.length == 1 || loadWithStore);

		if (stores.length == 1) {
			// if only one store, select it by default
			document.getElementById("cnc-radio-button-0").checked = true;
			showSingleStoreMessage = true;
			populateStoreDetailsAndDisplayBlock(stores[0], showSingleStoreMessage);
			postRequestToBackend(stores[0], true);
		} else {
			if (loadWithStore) {
				// if a store ID exists in the given session, keep it selected
				document.getElementById("cnc-radio-button-" + loadWithStoreIndex).checked = true;
				populateStoreDetailsAndDisplayBlock(loadWithStore, showSingleStoreMessage);
			}
			performClickAndCollectSelection(true);
		}
	}

}

/**
 * Function handle click and collect selection
 * @param isOptIn
 */
function performClickAndCollectSelection(isOptIn) {
	const storeSelectionRadioGroup = $('#storeSelectionRadioGroup');
	if (isOptIn) {
		$('#shippingNotes').hide();
		$('#shipping-form').hide();
		$('#address-form').hide();
		$('#shipping-method-list').hide();

		$('#shipping-method-ClickAndCollect').attr('checked', 'checked');
		// add ClickAndCollect shipping method to the available methods list and select it after
		updateShippingMethodList();
		selectShippingMethod(getClickAndCollectShippingMethodId());
	} else {
		storeSelectionRadioGroup.hide();
		$('#shippingNotes').show();
		$('#shipping-form').show();
		$('#address-form').show();
		$('#shipping-method-list').show();
		$('#singleStoreMsg').hide();
		$('#storeDetailsSection').hide();

		$('#shipping-method-ClickAndCollect').removeAttr('checked');

		// clear form fields
		clearAddressForm();
		clearField($phone);
		clearField($email);

		selectPreferredAddress();
		/**
		 * update shipping methods should be called only after posting the request to backend
		 * removes ClickAndCollect shipping method from available methods list
		 */
		updateShippingMethodList();
	}
}

/**
 * Function posts store selection request to backend
 * @param storeId
 */
function postRequestToBackend(store, isOptIn) {
	const actionUrl = isOptIn ? Urls.processStoreSelection : Urls.processStoreUnSelection;
	$.ajax({
		type: "POST",
		dataType: "json",
		cache: false,
		contentType: "application/json",
		url: actionUrl,
		data: JSON.stringify({storeId: store ? store.ID : ''})
	}).done(function (data) {
		if (!data) {
			// eslint-disable-next-line no-console
			console.error('bad response in postRequestToBackend');
			return false;
		}
		performClickAndCollectSelection(isOptIn);
	}).fail(function (xhr, textStatus) {
		// failed
		if (textStatus === "parsererror") {
			// eslint-disable-next-line no-console
			console.error('parseError in postRequestToBackend. Bad response');
		} else {
			// eslint-disable-next-line no-console
			console.error('Server connection Error in postRequestToBackend');
		}
		$('#singleStoreMsg').hide();
		$('#storeDetailsSection').hide();
	});
}

/**
 * Function retrieves ID of shipping method from available list of shipping methods on DOM
 * @returns string - the ClickAndCollect shipping method ID
 */
function getClickAndCollectShippingMethodId() {
	let shippingMethodId;

	const allShippingMethods = $('#shipping-method-list :input');
	for (let i = 0; i < allShippingMethods.length; i++) {
		if ((allShippingMethods[i].id).indexOf('ClickAndCollect') > -1) {
			shippingMethodId = (allShippingMethods[i].id).replace('shipping-method-', '');
			break;
		}
	}
	return shippingMethodId;
}

/**
 * Function does the following when SmartMarketing Optin is enabled for site
 * - hides marketing optin and T&Cs elements on the UI
 * - triggers ajax call to check if entered email is already opted in.
 * - when opted-in the opt-in checkbox and the T&Cs are hidden
 * - when NOT opted-in, the opt-in checkbox and T&Cs are displayed
 * - if sms marketing is enabled, show sms marketing optin checkbox if the given
 *   phone number is not opted in for sms marketing.
 */
function validateAndInitialiseMarketingOptin() {
	const newsletterOptin = document.querySelector("#newsletterOptin");
	const termsAndConditions = document.querySelector("#termsAndConditions");
	const rewardsSignup = document.querySelector("#rewardsSignup");

	const smartOptinEnabled = !!(
		newsletterOptin &&
		newsletterOptin.dataset &&
		newsletterOptin.dataset.smartOptinEnabled == "true"
	);

	if (smartOptinEnabled) {
		if ($email.valid()) {
			const enteredEmail = $email.val();
			const actionUrl = newsletterOptin.dataset.getContactApi;
			const autoOptIn = $optChk.data("autooptin");

			const getContactApiData = {
				email: enteredEmail,
				phone: null
			};

			$.ajax({
				type: "GET",
				dataType: "json",
				cache: false,
				contentType: "application/json",
				data: getContactApiData,
				url: actionUrl,
				beforeSend: function () {
					termsAndConditions.style["height"] = termsAndConditions.scrollHeight + "px";
					newsletterOptin.style["height"] = newsletterOptin.scrollHeight + "px";
				}
			})
				.done(function (data) {
					data = JSON.parse(data);
					// Newsletter subscription
					if (data && data.newsletterOptinStatus && data.newsletterOptinStatus != "SUBSCRIBED") {
						newsletterOptin.classList.remove("hidden");
						termsAndConditions.classList.remove("hidden");
						AccessibilityHelper.toggleAccessibility($(newsletterOptin), true);
						if (autoOptIn) {
							// re-optin if site preferense is set to auto optin marketinng email
							$optChk.prop('checked', true);
						}
					} else {
						newsletterOptin.classList.add("hidden");
						if (!rewardsSignup) {
							termsAndConditions.classList.add("hidden");
						}
						AccessibilityHelper.toggleAccessibility($(newsletterOptin), false);
						if ($optChk.is(":checked")) {
							$optChk.prop("checked", false);
						}
					}
					toggleOptInDiscountPromo();
				})
				.fail(function () {
					// failed - treat it as smartOptin is disabled
					newsletterOptin.classList.remove("hidden");
					termsAndConditions.classList.remove("hidden");
				});
		} else {
			// invalid email
			newsletterOptin.classList.add("hidden");
			if (!rewardsSignup) {
				termsAndConditions.classList.add("hidden");
			}
			AccessibilityHelper.toggleAccessibility($(newsletterOptin), false);
			if ($optChk.is(":checked")) {
				$optChk.prop("checked", false);
				toggleOptInDiscountPromo();
			}
		}
	}
}


/**
 * Function does the following when SMS marketing is enabled for site
 * - hides SMS optin elements on the UI
 * - triggers ajax call to check if entered mobile number is already opted in.
 * - if sms marketing is enabled, show sms marketing optin checkbox if the given
 *   phone number is not opted in for sms marketing.
 */
function validateAndInitialiseSMSOptin() {
	const smsMarketingOptin = document.querySelector("#smsMarketingOptin");

	const smsMarketingEnabled = !!(
		smsMarketingOptin &&
		smsMarketingOptin.dataset &&
		smsMarketingOptin.dataset.smsMarketingEnabled == "true"
	);
	if (smsMarketingEnabled) {
		if ($phone.valid()) {
			const enteredPhone = $phone.val();
			const actionUrl = smsMarketingOptin.dataset.smsGetInfoUrl;
			const getSMSSubscriptionInfoData = {
				mobileNumber: enteredPhone
			};

			$.ajax({
				type: "GET",
				dataType: "json",
				cache: true,
				contentType: "application/json",
				data: getSMSSubscriptionInfoData,
				url: actionUrl
			}).done(function () {
				smsMarketingOptin.classList.add("hidden");
				$smsOptChk.prop("checked", false);
			}).fail(function () {
				if (smsMarketingOptin.classList.contains("hidden")) {
					const smsAutoOptIn = $smsOptChk.data("autooptin");
					$smsOptChk.prop("checked", smsAutoOptIn);
					smsMarketingOptin.classList.remove("hidden");
				}
			});
		}
	}
}

/**
 * @function toggleOptInDiscountPromo()
 * @description Apply/Remove optin discount promo to the current basket
 */
function toggleOptInDiscountPromo() {
	var optIn = $optChk.is(":checked");
	var href = $optChk.data("togglepromo");

	if (href.length > 0) {
		progress.show($('.checkout-summary'));
		var params = {
			optin: optIn,
			email: $email.val(),
			phone: $phone.val()
		};
		href = util.appendParamsToUrl(href, params);

		$.ajax(href).done(function (summaryData) {
			if (summaryData) {
				$('.checkout-summary').html(summaryData);
			} else {
				progress.hide($('.checkout-summary'));
			}
		});
	}
}

/**
 * @function setAddressFieldReadOnly()
 * @description Set all address fields readonly property
 */
function setAddressFieldReadOnly(readOnly) {
	$firstName.prop('readonly', readOnly);
	$lastName.prop('readonly', readOnly);
	$companyName.prop('readonly', readOnly);
	$address1.prop('readonly', readOnly);
	$address2.prop('readonly', readOnly);
	$state.prop('readonly', readOnly);
	$zip.prop('readonly', readOnly);
	$city.prop('readonly', readOnly);
	$country.prop('readonly', readOnly);
}

/**
 * @function updatePaymentMethods()
 * @description
 */
var initializeEvents = function () {
	// ---- Watch inputs for shipping method updates
	$('.shipping-form').on('change', 'input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_zip"], input#poBox', function (e) {
		e.preventDefault();

		if ($address1.val() != "" && $address2.val() != "" && $country.val() != "" && $state.val() != "" && $zip.val() != "" && $city.val() != "") {
			updateShippingMethodListWrapper();
		}
	});

	$('.checkout-shipping').on("change", "input[name$='_addToEmailList']", function () {
		toggleOptInDiscountPromo();
	});

	// ---- rebind the radio buttons onclick function to a handler.
	$('#shipping-method-list').on("click", "[name$='_shippingMethodID']", function () {
		var _shippingMethodSelected = $('#shipping-method-list').find("input[name$='_shippingMethodID']:checked").val();
		selectShippingMethod(_shippingMethodSelected);
	});

	// ---- Gift Messaging
	if ($(".gift-message").length > 0) {
		$(".gift-message textarea").on("keyup keydown", function () {
			var max = $(".gift-message").data("giftmessagemax");
			var value = $(this).val();
			var left = max - value.length;
			if (left < 0) {
				$(this).val(value.slice(0, left));
				left = 0;
			}
			$(".gift-message span.count").text(left);
		});
	}

	// ---- Gift Wrap
	if ($(".gift-wrap").length > 0) {
		$(".gift-wrap input[name$='_isGiftWrap'], .gift-wrap .checkbox label").on("click", function () {
			var _chk = this;

			$.ajax({
				type: "POST",
				url: Urls.giftWrap,
				cache: false,
				data: {giftwrap: $(_chk).attr("checked")},
				success: function () {
					checkout.updateSummary('shipping');
				},
				error: function () {
				}
			});
		});
	}

	// ---- select address from list (or new)
	$(".checkout-shipping").on("change", ".addresslist input", function (e) {
		const clickAndCollectCheckbox = document.querySelector('#clickAndCollectToggle');

		if (clickAndCollectCheckbox && clickAndCollectCheckbox.checked) {
			return;
		}
		e.preventDefault();

		// Toggle the address form
		address.showAddressForm(this, '#shipping-address-new');

		if ($(this).is('#shipping-address-new')) {
			clearAddressForm();
			setAddressFieldReadOnly(false);
		} else {
			// Repopulate inputs
			setAddressFieldReadOnly(true);
			var _addressID = $(this).val();
			var _address = null;
			if (_addressID.indexOf("??") > 0) {
				_address = $(this).find(':selected').data('address');
			}
			if (!_addressID) {
				hideStoredAddressError();
				return;
			}
			$addressId = _addressID;
			updateAddressForm(_addressID, _address);
		}
		toggleUpdateSavedAddressValue(false);
	}).change();

	selectPreferredAddress();

	// ---- Final page submission
	$("button[name$='_shippingAddress_save']").on("click", function () {
		var $this = $(this);

		var _shippingMethodSelected = $('#shipping-method-list').find("input[name$='_shippingMethodID']:checked").val();
		// Push GTM Checkout Action Data
		gtmTracking.pushCheckoutAction($this, {checkout_option: _shippingMethodSelected});

		// Sniffing for errors
		var errorField = $shippingForm.find("div.field-error input").first();
		// Error in an accordion
		var accordionClosed = errorField.parents('.accordion-closed');
		if (accordionClosed.length > 0) {
			accordion.openAccordion(accordionClosed);
			setAddressFieldReadOnly(false);
		}
	});

	$("#clickAndCollectToggle").on("click", function () {
		executeClickAndCollectToggle();
	});

	$("#storeSelectionRadioGroup").on("change", function () {
		executeStoreSelectionAction();
	});

	$email.on("change", function () {
		validateAndInitialiseMarketingOptin();
	});

	$("body").on("updateShippingMethodList", function () {
		updateShippingMethodListWrapper();
	});

	// when a logged in user visits the page with prefilled email address
	if ($email && $email.val().length > 0) {
		validateAndInitialiseMarketingOptin();
	}

	$phone.on("keyup paste", _throttle(function () {
		validateAndInitialiseSMSOptin();
	}, 600));

	if ($phone && $phone.val().length > 0) {
		validateAndInitialiseSMSOptin();
	}

	initializeEditSavedAddress();
};

/**
 * Hooks up click event to trigger "edit saved address" mode.
 */
function initializeEditSavedAddress() {
	$('.selectaddress-box').on('click', '.edit-saved-address', function (e) {
		e.preventDefault();
		const $savedAddressItem = $(this).parents('.address-item').find('input.address');
		setAddressFieldReadOnly(false);
		address.showAddressForm($savedAddressItem, '#' + $savedAddressItem.attr('id'));
		toggleUpdateSavedAddressValue(true);
	});
}

/**
 * Sets the update-saved-address form input field. This will inform the back end
 * to save the given saved shipping address when the form gets submitted.
 * @param on
 */
function toggleUpdateSavedAddressValue(on) {
	// Hidden input field to indicate saved address needs to be updated.
	$('.selectaddress-box input[name$="updateSavedAddress"]').val(!!on);

	// Save address checkbox should be hidden when updating saved address.
	const saveAddressCheckbox = $('.save-address');
	on ? saveAddressCheckbox.hide() : saveAddressCheckbox.show();
}

exports.init = function () {
	initializeDOM();
	initializeEvents();
};

exports.updateShippingMethodList = updateShippingMethodListWrapper;
